require('./bootstrap');

$(document).ready(function(){
    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 25) {
            $(".body").addClass("scrolled");
        }else{
            $(".body").removeClass("scrolled");
        }
    });

    $(".hamburger").click(function(){
        $(this).toggleClass("is-active");
        $('.nav-links').toggleClass("show");
        $('body').toggleClass("menu-open");
        $('#mainnav').toggleClass("menu-open");
    });
});
